import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const lpaReceivingQuestionsState = atom({
  key: 'lpaReceivingQuestionsState',
  default: {
    LPAQuestions: [],
  },
  effects_UNSTABLE: [persistAtom],
});

