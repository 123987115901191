import React from 'react';
import { useRecoilState } from 'recoil';
import LpaAdmin from './Pages/LPAStoreAnalysis/LPAStoreAnalysisAdmin/LpaAdmin';
import LpaBackups from './Pages/LPAStoreAnalysis/LPAStoreAnalysisBackups/LpaBackups';
import LpaCashOffice from './Pages/LPAStoreAnalysis/LPAStoreAnalysisCashOffice/LpaCashOffice';
import LpaFrontline from './Pages/LPAStoreAnalysis/LPAStoreAnalysisFrontline/LpaFrontLine';
import LpaReceiving from './Pages/LPAStoreAnalysis/LPAStoreAnalysisReceiving/LpaReceiving';
import LpaSalesFloor from './Pages/LPAStoreAnalysis/LPAStoreAnalysisSalesFloor/LpaSalesFloor';
import LpaSecurity from './Pages/LPAStoreAnalysis/LPAStoreAnalysisSecurity/LpaSecurity';
import './AuditerPage.css';

const AuditerPage = () => {
  

  return (
    <div className="App">
      <div>
        <h2>New Audit</h2>
      </div>
     

      {/* <LpaAdmin/>  */}
      <LpaBackups/> 
      <LpaFrontline/> 
       <LpaSecurity/>
      {/* <LpaReceiving/> 
       <LpaSalesFloor/> */}
      <LpaCashOffice/> 
     
    </div>
  );
}

export default AuditerPage;
