import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import '../../Styles/LpaStoreAnalysisStyles.css'
import './Home.css'
import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import { auditCodeAtom } from '../../Recoil/auditCodeAtom';
import { lpaAdminQuestionsState } from '../../Recoil/LpaAdminQuestionsState';
import { lpaAdminDataState } from '../../Recoil/LpaAdminDataState';
import { lpaBackupQuestionsState } from '../../Recoil/LpaBackupQuestionsState';
import { lpaBackupDataState } from '../../Recoil/LpaBackupDataState';
import { lpaFrontlineQuestionsState } from '../../Recoil/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../Recoil/LpaFrontLineDataState';
import { lpaSecurityQuestionsState } from '../../Recoil/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../Recoil/LpaSecurityDataState';
import { lpaReceivingQuestionsState } from '../../Recoil/LpaReceivingQuestionsState';
import { lpaReceivingDataState } from '../../Recoil/LpaReceivingDataState';
import { lpaSalesFloorQuestionsState } from '../../Recoil/LpaSalesFloorQuestionsState';
import { lpaSalesFloorDataState } from '../../Recoil/LpaSalesFloorDataState';
import { lpaCashOfficeQuestionsState } from '../../Recoil/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../Recoil/LpaCashOfficeDataState';
import { auditLogsAtom } from '../../Recoil/auditlogsAtom';

const Home = () => {
    const auditCodeData = useRecoilValue(auditHeaderDataAtom);
    const auditCodeValue = useRecoilValue(auditCodeAtom)
    const setAuditCodeValue = useSetRecoilState(auditCodeAtom)
    const lpaAdminQuestions = useRecoilValue(lpaAdminQuestionsState);
    const lpaAdminData = useRecoilValue(lpaAdminDataState);
    const lpaDataBackups = useRecoilValue(lpaBackupDataState);
    const lpaDataCashOffice = useRecoilValue(lpaCashOfficeDataState);
    const lpaDataFrontline = useRecoilValue(lpaFrontlineDataState);
    const lpaDataReceiving = useRecoilValue(lpaReceivingDataState);
    const lpaDataSalesFloor = useRecoilValue(lpaSalesFloorDataState);
    const lpaDataSecurity = useRecoilValue(lpaSecurityDataState);

    const lpaBackupQuestions = useRecoilValue(lpaBackupQuestionsState);
    const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);
    const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);
    const lpaReceivingQuestions = useRecoilValue(lpaReceivingQuestionsState);
    const lpaSalesFloorQuestions = useRecoilValue(lpaSalesFloorQuestionsState);
    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);
    const LpaBackupData = useRecoilValue(lpaBackupDataState);
    const lpaFrontlineData = useRecoilValue(lpaFrontlineDataState);
    const lpaSecurityData = useRecoilValue(lpaSecurityDataState);
    const lpaReceivingData = useRecoilValue(lpaReceivingDataState);
    const lpaSalesFloorData = useRecoilValue(lpaSalesFloorDataState);
    const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);

    const [auditLogs, setAuditLogs] = useRecoilState(auditLogsAtom)
    const [isLoading, setIsLoading] = useState(true);

    const resetAuditCodeAtom = useResetRecoilState(auditCodeAtom)
    const resetAuditHeaderDataAtom = useResetRecoilState(auditHeaderDataAtom);
    const resetAuditLogsAtom = useResetRecoilState(auditLogsAtom);
    const resetLpaAdminQuestionsState = useResetRecoilState(lpaAdminQuestionsState);
    const resetLpaAdminData = useResetRecoilState(lpaAdminDataState);
    const resetLpaBackupQuestions = useResetRecoilState(lpaBackupQuestionsState);
    const resetLpaBackupData = useResetRecoilState(lpaBackupDataState);
    const resetLpaFrontlineQuestions = useResetRecoilState(lpaFrontlineQuestionsState);
    const resetLpaFrontlineData = useResetRecoilState(lpaFrontlineDataState);
    const resetLpaSecurityQuestions = useResetRecoilState(lpaSecurityQuestionsState);
    const resetLpaSecurityData = useResetRecoilState(lpaSecurityDataState)
    const resetLpaReceivingQuestions = useResetRecoilState(lpaReceivingQuestionsState);
    const resetLpaReceivingData = useResetRecoilState(lpaReceivingDataState);
    const resetLpaSalesFloorQuestions = useResetRecoilState(lpaSalesFloorQuestionsState);
    const resetLpaSalesFloorData = useResetRecoilState(lpaSalesFloorDataState);
    const resetLpaCashOfficeQuestions = useResetRecoilState(lpaCashOfficeQuestionsState);
    const resetLpaCashOfficeData = useResetRecoilState(lpaCashOfficeDataState);

    useEffect(() => {
        localStorage.removeItem('auditHeaderDataAtom');
        localStorage.removeItem('auditLogsAtom');
        localStorage.removeItem('auditCodeAtom');

        localStorage.removeItem('lpaAdminQuestionsState');
        localStorage.removeItem('lpaAdminDataState');

        localStorage.removeItem('lpaBackupQuestionsState');
        localStorage.removeItem('lpaBackupDataState');

        localStorage.removeItem('lpaCashOfficeQuestionsState');
        localStorage.removeItem('lpaCashOfficeDataState');

        localStorage.removeItem('lpaFrontlineQuestionsState');
        localStorage.removeItem('lpaFrontlineDataState');

        localStorage.removeItem('lpaReceivingQuestionsState');
        localStorage.removeItem('lpaReceivingDataState');

        localStorage.removeItem('lpaSalesFloorQuestionsState');
        localStorage.removeItem('lpaSalesFloorDataState');

        localStorage.removeItem('lpaSecurityQuestionsState');
        localStorage.removeItem('lpaSecurityDataState');

    }, []);
    
       
    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 6; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          code += characters[randomIndex];
        }
        return code;
      };


    const navigate = useNavigate();

    const handleNavClick = () => {
        console.log("lpaAdminData:", lpaAdminData);

        setAuditCodeValue((prevLpaData) => ({
            ...prevLpaData,
            auditCode: generateRandomCode()
          }));
    
        // Ensure each property is properly initialized as an empty array
        if (!Array.isArray(lpaAdminData.responses)) {
            lpaAdminData.responses = [];
        }
        if (!Array.isArray(lpaAdminData.notes)) {
            lpaAdminData.notes = [];
        }
        if (!Array.isArray(lpaAdminData.scores)) {
            lpaAdminData.scores = [];
        }
        
    
        if (lpaAdminData.responses.length === 0 && lpaAdminData.notes.length === 0 &&
            lpaAdminData.scores.length === 0 ) {
            navigate('/auditerPage')
        } else {
            alert("Please complete the current audit before starting a new one");
        }
        console.log("Audit Atom: ", auditCodeValue)
    }
    

//Helper function to check if atoms are fully filled
const allAtomsAreFullyFilled = () => {
    if ( !lpaAdminData || !lpaDataBackups || !lpaDataCashOffice || !lpaDataFrontline || !lpaDataReceiving || !lpaDataSalesFloor || !lpaDataSecurity) return false; // If either lpaDataAdmin or lpaDataBackups is empty, return false
    const { responses: adminResponses, scores: adminScores } = lpaAdminData;
    const { responses: backupsResponses, scores: backupsScores } = lpaDataBackups;
    const { responses: cashOfficeResponses, scores: cashOfficeScores } = lpaDataCashOffice;
    const { responses: frontlineResponses, scores: frontlineScores } = lpaDataFrontline;
    const { responses: receivingResponses, scores: receivingScores } = lpaDataReceiving;
    const { responses: salesFloorResponses, scores: salesFloorScores } = lpaDataSalesFloor;
    const { responses: securityResponses, scores: securityScores } = lpaDataSecurity;
    return (
        adminResponses.length === lpaAdminQuestions.LPAQuestions.length &&  
        adminScores.length === lpaAdminQuestions.LPAQuestions.length 
        &&
        backupsResponses.length === lpaBackupQuestions.LPAQuestions.length && 
        backupsScores.length === lpaBackupQuestions.LPAQuestions.length
        && 
        cashOfficeResponses.length === lpaCashOfficeQuestions.LPAQuestions.length &&  
        cashOfficeScores.length === lpaCashOfficeQuestions.LPAQuestions.length 
        && 
        frontlineResponses.length === lpaFrontlineQuestions.LPAQuestions.length &&  
        frontlineScores.length === lpaFrontlineQuestions.LPAQuestions.length 
        && 
        receivingResponses.length === lpaReceivingQuestions.LPAQuestions.length &&  
        receivingScores.length === lpaReceivingQuestions.LPAQuestions.length 
        && 
        salesFloorResponses.length === lpaSalesFloorQuestions.LPAQuestions.length &&  
        salesFloorScores.length === lpaSalesFloorQuestions.LPAQuestions.length 
        && 
        securityResponses.length === lpaSecurityQuestions.LPAQuestions.length &&  
        securityScores.length === lpaSecurityQuestions.LPAQuestions.length 
        
    );

};

    // Helper function to check if any of the atoms have data
    const anyAtomHasData = () => {
        const lpaDataAtoms = [
            lpaAdminData,
            lpaDataBackups,
            lpaDataCashOffice,
            lpaDataFrontline,
            lpaDataReceiving,
            lpaDataSalesFloor,
            lpaDataSecurity,
        ];

        return lpaDataAtoms.some((data) => {
            if (!data) return false; // If the data is empty, return false
            const { responses, scores } = data;
            return responses.length > 0 || scores.length > 0;
        });
    };

   
    const getLatestDate = () => {
        const dates = [
          lpaAdminData.dateTime,
          lpaDataBackups.dateTime,
          lpaDataCashOffice.dateTime,
          lpaDataFrontline.dateTime,
          lpaDataReceiving.dateTime,
          lpaDataSalesFloor.dateTime,
          lpaDataSecurity.dateTime,
        ];
      
        // Filter out undefined values and parse the dates manually
        const validDates = dates
          .filter(date => date)
          .map(date => new Date(date.replace(' at ', ' ')));
      
        // Find the maximum (latest) date
        const latestDate = new Date(Math.max(...validDates));
        
        // Format the latest date as needed
        const formattedLatestDate = latestDate.toLocaleString('en-US', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        });
        console.log("Dates:", dates)
        console.log("valid Dates:", validDates)
        console.log("formatted Latest Date:", formattedLatestDate)
        return formattedLatestDate;
      };
      
      const latestFormattedDate = getLatestDate();

      

    const handleClick =() =>{
        // const dateTime = new Date().toLocaleString('en-US', {
        //     weekday: 'long',
        //     day: 'numeric',
        //     month: 'long',
        //     year: 'numeric',
        //   });

        //   setAuditCodeValue((prevLpaData) => ({
        //     ...prevLpaData,
        //     dateTime,
        //   }));

        //   const adminQuestions = lpaAdminQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const backupQuestions = lpaBackupQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const frontlineQuestions = lpaFrontlineQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const securityQuestions = lpaSecurityQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const receivingQuestions = lpaReceivingQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const salesFloorQuestions = lpaSalesFloorQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        //   const cashOfficeQuestions = lpaCashOfficeQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
      
        //     const promises = [
        //       axios.post("https://worxit.co.za/api/zamatrack/create/sectionAData.php", {
        //         auditCode: lpaAdminData.auditCode,
        //         section: lpaAdminData.section,
        //         quesId: adminQuestions,
        //         responses: lpaAdminData.responses,
        //         notes: lpaAdminData.notes,
        //         scores: lpaAdminData.scores,
        //         totalScore: lpaAdminData.totalScore
        //       }),
        //       axios.post("https://worxit.co.za/api/zamatrack/create/sectionBData.php", {
        //       auditCode: LpaBackupData.auditCode,
        //       section: LpaBackupData.section,
        //       quesId: backupQuestions,
        //       responses: LpaBackupData.responses,
        //       notes: LpaBackupData.notes,
        //       scores: LpaBackupData.scores,
        //       totalScore: LpaBackupData.totalScore
        //     }),
        //     axios.post("https://worxit.co.za/api/zamatrack/create/sectionCData.php", {
        //       auditCode: lpaFrontlineData.auditCode,
        //       section: lpaFrontlineData.section,
        //       quesId: frontlineQuestions,
        //       responses: lpaFrontlineData.responses,
        //       notes: lpaFrontlineData.notes,
        //       scores: lpaFrontlineData.scores,
        //       totalScore: lpaFrontlineData.totalScore
        //     }),
        //     axios.post("https://worxit.co.za/api/zamatrack/create/sectionDData.php", {
        //       auditCode: lpaSecurityData.auditCode,
        //       section: lpaSecurityData.section,
        //       quesId: securityQuestions,
        //       responses: lpaSecurityData.responses,
        //       notes: lpaSecurityData.notes,
        //       scores: lpaSecurityData.scores,
        //       totalScore: lpaSecurityData.totalScore
        //     }),
        //     axios.post("https://worxit.co.za/api/zamatrack/create/sectionEData.php", {
        //       auditCode: lpaReceivingData.auditCode,
        //       section: lpaReceivingData.section,
        //       quesId: receivingQuestions,
        //       responses: lpaReceivingData.responses,
        //       notes: lpaReceivingData.notes,
        //       scores: lpaReceivingData.scores,
        //       totalScore: lpaReceivingData.totalScore
        //     }),
        //     axios.post("https://worxit.co.za/api/zamatrack/create/sectionFData.php", {
        //       auditCode: lpaSalesFloorData.auditCode,
        //       section: lpaSalesFloorData.section,
        //       quesId: salesFloorQuestions,
        //       responses: lpaSalesFloorData.responses,
        //       notes: lpaSalesFloorData.notes,
        //       scores: lpaSalesFloorData.scores,
        //       totalScore: lpaSalesFloorData.totalScore
        //     }),
        //     axios.post("https://worxit.co.za/api/zamatrack/create/sectionGData.php", {
        //       auditCode: lpaCashOfficeData.auditCode,
        //       section: lpaCashOfficeData.section,
        //       quesId: cashOfficeQuestions,
        //       responses: lpaCashOfficeData.responses,
        //       notes: lpaCashOfficeData.notes,
        //       scores: lpaCashOfficeData.scores,
        //       totalScore: lpaCashOfficeData.totalScore
        //     })
        //     ];
        //     Promise.all(promises)
        // .then((results) => {
          
             navigate('/findingsLogs')
           
        //   // Handle the results if needed
        //   console.log(results);
        // })
        // .catch((error) => {
        //   // Handle any errors that might occur during the process
        //   console.error("Error:", error);
        // });
        
    }

    useEffect(() => {
        // Fetch data from the API and update LPAQuestions in the atom
        const fetchData = async () => {
          try {
            const response = await axios.get('https://worxit.co.za/api/zamatrack/view/viewAuditLogs.php');
            const newData = response.data.records;
            // Update the LPAQuestions in the atom if newData is an array
            if (Array.isArray(newData)) {
                setAuditLogs(newData);
           
            }
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        
        fetchData()
      }, [setAuditLogs]);

      const auditLogsEmpty = () => auditLogs.every(data => !data);


      if (!Array.isArray(auditLogs)) {
        return <div>Loading...</div>; // or return an empty component if desired
      }

      const handleLogout = () => {
        resetAuditCodeAtom();
        resetAuditHeaderDataAtom();
        resetAuditLogsAtom();
        resetLpaAdminQuestionsState();
        resetLpaBackupQuestions();
        resetLpaCashOfficeQuestions();
        resetLpaFrontlineQuestions();
        resetLpaReceivingQuestions();
        resetLpaSalesFloorQuestions();
        resetLpaSecurityQuestions();

        resetLpaAdminData();
        resetLpaBackupData();
        resetLpaFrontlineData();
        resetLpaCashOfficeData();
        resetLpaReceivingData();
        resetLpaSalesFloorData();
        resetLpaSecurityData()

        navigate('/')
      }

      const parseDateString = (dateString) => {
        const parts = dateString.split(' at ');
        const formattedDate = parts[0].replace('Friday, ', '').replace(/(st|nd|rd|th),/, ',') + ' ' + parts[1];
        return new Date(formattedDate);
      };
      
    return (
        <div className='conatinerBackground'>
            <div className='auditLogoutConatiner'>
                <div className='auditBtnCont'>
                <button className='auditBtn' onClick={handleNavClick}>Start New Audit</button>
            </div>
            <div className='logoutBtnCont'>
                <button className='logoutBtn' onClick={handleLogout}>LogOut</button>
            </div>
            </div>
            

             <div className='progressContainer'>
            {anyAtomHasData() ? (
                allAtomsAreFullyFilled() ? (
                    <table className='progresstbl'>
                        <thead>
                            <tr>
                                <th className="DateColWidth">Start Date</th>
                                <th className="siteNoColWidth">Site No</th>
                                <th className="ProgessColWidth">Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="DateColWidth">{latestFormattedDate}</td>
                                <td className="siteNoColWidth">{auditCodeData[0]?.site}</td>
                                <td className="ProgessColWidth"><button className="btn btn-success" onClick={handleClick}>Findings</button></td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <table className='progresstbl'>
                        <thead>
                            <tr>
                                <th className="DateColWidth">Start Date</th>
                                <th className="siteNoColWidth">Site No</th>
                                <th className="ProgessColWidth">Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="DateColWidth">{latestFormattedDate}</td>
                                <td className="siteNoColWidth">{auditCodeData[0]?.site}</td>
                                <td className="ProgessColWidth"><Link className="busyWithAuditBtn" to={'/auditerPage'}>Busy with Audit</Link></td>
                            </tr>
                        </tbody>
                    </table>
                    )
                    ) : (
                        <div>No audits</div>
                    )}
                </div>
                <div className='passedTableTitle'>
                    <h4 className='passedTitleLbl'>Past Audits</h4>
                </div>
                <div className='passedTable'>
                    {!auditLogsEmpty() ? (
                        <table>
                            <thead>
                                <tr>
                                    <th className='compByColumnWidth'>Completed By</th>
                                    <th className='siteColumnWidth'>Site</th>
                                    <th className='dateColumnWidth'>Completion Date</th>
                                    <th className='latitudeColumnWidth'>Latitude</th>
                                    <th className='longitudeColumnWidth'>Longitude</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {auditLogs
                                .sort((a, b) => parseDateString(b.completionDate) - parseDateString(a.completionDate))                               
                                .map((details, index) => (
                                    <tr key={index}>
                                    <td className='compByColumnWidth'>{details.user}</td>
                                    <td className='siteColumnWidth'>{details.site}</td>
                                    <td className='dateColumnWidth'>{details.completionDate}</td>
                                    <td className='latitudeColumnWidth'>{details.latitude}</td>
                                    <td className='longitudeColumnWidth'>{details.longitude}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        ) : (
                        <div>
                            <label>No Data</label>
                        </div>
                    )}
                </div>
            </div>  
        );
    }

export default Home;