import React from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/login";
import AuditerPage from "./AuditerPage";
import Home from "./Pages/Home/Home";
import FeedbackPage from "./Pages/FeedbackPage/feedbackPage";
import FindingsLogs from "./Pages/FindingsLogs/findingsLogs";

const RootComponent = () => {
  return (
     <RecoilRoot>
        <Routes>
          <Route path="/" element={<Login />} /> 
          <Route path="/home" element={<Home />} />
          <Route path="/auditerPage" element={<AuditerPage />} />
          <Route path="/feedbackPage" element={<FeedbackPage />} />
          <Route path="/findingsLogs" element={<FindingsLogs />} />
        </Routes>
     </RecoilRoot>
  );
};

export default RootComponent;