import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import LpaAdminFeedback from '../LPAStoreFeedback/LPAStoreFeedbackAdmin/LpaAdminFeedback';
import LpaBackupsFeedback from '../LPAStoreFeedback/LPAStoreFeedbackBackups/LpaBackupsFeedback';
import LpaFrontLineFeedback from '../LPAStoreFeedback/LPAStoreAnalysisFrontline/LpaFrontLineFeedback';
import LpaSecurityFeedback from '../LPAStoreFeedback/LPAStoreFeedbackSecurity/LpaSecurityFeedback';
import LpaReceivingFeedback from '../LPAStoreFeedback/LPAStoreFeedbackReceiving/LpaReceivingFeedback';
import LpaSalesFloorFeedback from '../LPAStoreFeedback/LPAStoreFeedbackSalesFloor/LpaSalesFloorFeedback';
import LpaCashOfficeFeedback from '../LPAStoreFeedback/LPAStoreFeedbackCashOffice/LpaCashOfficeFeedback';

import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import { auditCodeAtom } from '../../Recoil/auditCodeAtom';
import { lpaAdminDetailsState } from '../../Recoil/LpaAdminDetailsState';
import { lpaBackupsDetailsState } from '../../Recoil/LpaBackupsDetailsState';
import { lpaFrontlineDetailsState } from '../../Recoil/LpaFrontlineDetailsState';
import { lpaSecurityDetailsState } from '../../Recoil/LpaSecurityDetailsState';
import { lpaReceivingDetailsState } from '../../Recoil/LpaReceivingDetailsState';
import { lpaSalesFloorDetailsState } from '../../Recoil/LpaSalesFloorDetailsState';
import { lpaCashOfficeDetailsState } from '../../Recoil/LpaCashOfficeDetailsState';

import './feedbackPage.css';


const FeedbackPage = () => {
  const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
  const auditCodeVale = useRecoilValue(auditCodeAtom);
  const receivingScore = useRecoilValue(lpaReceivingDetailsState);
  const salesFloorScore = useRecoilValue(lpaSalesFloorDetailsState);
  const fronlineScore = useRecoilValue(lpaFrontlineDetailsState);
  const securityScore = useRecoilValue(lpaSecurityDetailsState);
  const backupsScore = useRecoilValue(lpaBackupsDetailsState);
  const adminScore = useRecoilValue(lpaAdminDetailsState);
  const cashOfficeScore = useRecoilValue(lpaCashOfficeDetailsState);


  const recieivingScoreArray = Array.isArray(receivingScore) ? receivingScore : [];
  const filteredReceivingDetails = recieivingScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const salesFloorScoreArray = Array.isArray(salesFloorScore) ? salesFloorScore : [];
  const filteredSalesFloorDetails = salesFloorScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const frontlineScoreArray = Array.isArray(fronlineScore) ? fronlineScore : [];
  const filteredFrontlineDetails = frontlineScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const securityScoreArray = Array.isArray(securityScore) ? securityScore : [];
  const filteredSecurityDetails = securityScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const backupsScoreArray = Array.isArray(backupsScore) ? backupsScore : [];
  const filteredBackupsDetails = backupsScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const adminScoreArray = Array.isArray(adminScore) ? adminScore : [];
  const filteredAdminDetails = adminScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  
   
  const cashOfficeScoreArray = Array.isArray(cashOfficeScore) ? cashOfficeScore : [];
  const filteredCashOfficeDetails = cashOfficeScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  
   
  

  


  return (
    <div className="feedbackContainer">
      
              
          
      {/* Render components based on the joined data */}
      <LpaAdminFeedback />
      <LpaBackupsFeedback />
      <LpaFrontLineFeedback />
      <LpaSecurityFeedback />
      <LpaReceivingFeedback />
      <LpaSalesFloorFeedback />
      <LpaCashOfficeFeedback />

      <div className='tableFeedback'>
        <div className='firstColumn'>
          <div className='userRow'>
            {auditHeaderData[0]?.user}
          </div>
          <div className='dateRow'>
            Date: {auditHeaderData.dateTime}
          </div>
         
        </div>
        <div className='secondColumn'>
          <div className='headerRow'>
            <label className='headerTile'>Total LPA Score</label>
          </div>
          <div className='firstRow'>
            {filteredReceivingDetails.length > 0 && (
                <label className='rowData'>Receiving = {filteredReceivingDetails[0].totalScore}%</label>
            )}
          </div>
          <div className='seondRow'>
            {filteredSalesFloorDetails.length > 0 && (
                  <label className='rowData'>Sale Floor = {filteredSalesFloorDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='thirdRow'>
            {filteredFrontlineDetails.length > 0 && (
                  <label className='rowData'>Frontline = {filteredFrontlineDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='forthRow'>
            {filteredSecurityDetails.length > 0 && (
                  <label className='rowData'>Security = {filteredSecurityDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='fifthRow'>
            {filteredBackupsDetails.length > 0 && (
                  <label className='rowData'>Backups = {filteredBackupsDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='sixthRow'>
        {filteredAdminDetails.length > 0 && (
          <label className='rowData'>Admin = {filteredAdminDetails[0].totalScore}%</label>
        )}
      </div>
          <div className='seventhRow'>
            {filteredCashOfficeDetails.length > 0 && (
                    <label className='rowData'>Cash Office = {filteredCashOfficeDetails[0].totalScore}%</label>
                )}
          </div>
          
        </div>
      </div>

    </div>
  );
};

export default FeedbackPage;
