import React,{useState, useEffect ,useCallback, useMemo} from 'react';
import axios from 'axios';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import "../../../Styles/LpaStoreAnalysisStyles.css"
import { lpaBackupQuestionsState } from '../../../Recoil/LpaBackupQuestionsState';
import { lpaBackupDataState } from '../../../Recoil/LpaBackupDataState';
import { auditCodeAtom } from '../../../Recoil/auditCodeAtom';

const LpaBackups = () => {

    const [lpaData, setLpaData] = useRecoilState(lpaBackupQuestionsState)
    const lpaDataQuestions = useRecoilValue(lpaBackupQuestionsState);
    const [lpaBackupData, setLpaBackupData] = useRecoilState(lpaBackupDataState);
    const { responses, notes, scores } = lpaBackupData;
    const [tempResponses, setTempResponses] = useState(responses);
    const [tempNotes, setTempNotes] = useState(notes);
    const [tempScores, setTempScores] = useState(scores)
    const [totalScore, setTotalScore] = useState(100);
    const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
    const auditCode = useRecoilValue(auditCodeAtom);
    const auditCodeValue =  auditCode.auditCode;
    const quesId = useRecoilValue(lpaBackupQuestionsState);
    const quesIdValues = quesId?.LPAQuestions?.map((question) => question.quesId) || [];
    const section = 'B';
    const navigate = useNavigate();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

   useEffect(() => {
    // Fetch data from the API and update LPAQuestions in the atom
    const fetchData = async () => {
      try {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionBQuestions.php');
        const newData = response.data.records;
        //console.log("Questions: ",lpaDataQuestions.LPAQuestions.questions)
        // Update the LPAQuestions in the atom
        setLpaData((prevLpaData) => ({
          ...prevLpaData,
          LPAQuestions: newData,
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [setLpaData]);

  useEffect(() => {
    console.log('Temp responses:', tempResponses);
    console.log('Temp scores:', tempScores);
    // Calculate if all rows are complete
    const areAllRowsComplete = tempResponses.length > 0 && tempScores.length > 0 && tempResponses.every(
      (response, index) =>
        response && response.trim() !== '' && tempScores[index] && tempScores[index].trim() !== ''
    );
    console.log('Are all rows complete ', areAllRowsComplete);
    setIsUpdateEnabled(areAllRowsComplete);

  }, [tempResponses, tempScores]);
  
    //--------------CALCULATIONS--------------------
    const na = 10;
    const pass = 10;
    const fail = 0;

     // Function to calculate and update total score
     const updateTotalScore = useCallback(() => {
        const questionsCount =  lpaDataQuestions.LPAQuestions.length *10;
        const naCount = tempScores.filter((score) => score === 'N/A').length;
        const naActual = na * naCount;
        const questionActual = questionsCount - naActual;
        const passCount = tempScores.filter((score) => score === 'Pass').length;
        const passActual = pass * passCount;
        let newTotalScore = (passActual / questionActual) * 100;
        newTotalScore = Math.ceil(newTotalScore); // Round the total score to the nearest whole number
        setTotalScore(newTotalScore);
        setLpaBackupData((prevLpaData) => ({
            ...prevLpaData,
            totalScore: newTotalScore,
        }));
    }, [tempScores, setLpaBackupData, lpaDataQuestions.LPAQuestions]);

    // useEffect to re-calculate total score whenever scores change
    useEffect(() => {
    updateTotalScore();
    }, [tempScores, updateTotalScore]);

    // Function to submit progress and send data to the server (or perform any other action)
    const submitProgress = () => {


      const dateTime = new Date().toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
  
    setTempResponses((prevResponses) => [...prevResponses]);
    setTempNotes((prevNotes) => [...prevNotes]);
  
      setLpaBackupData((prevLpaData) => ({
        ...prevLpaData,
        auditCode: auditCodeValue,
        section,
        quesId: quesIdValues,
        responses: tempResponses, // Use the temporary responses for the Recoil state
        notes: tempNotes, // Use the temporary notes for the Recoil state
        scores: tempScores,
        dateTime,
        totalScore,
      }));
      alert('Progress submitted successfully!');
      setIsFormSubmitted(true);
    };
  
    const updateProgress = () => {
  
  
      const dateTime = new Date().toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
  
    setTempResponses((prevResponses) => [...prevResponses]);
    setTempNotes((prevNotes) => [...prevNotes]);
  
      setLpaBackupData((prevLpaData) => ({
        ...prevLpaData,
        auditCode: auditCodeValue,
        section,
        quesId: quesIdValues,
        responses: tempResponses, // Use the temporary responses for the Recoil state
        notes: tempNotes, // Use the temporary notes for the Recoil state
        scores: tempScores,
        dateTime,
        totalScore,
      }));
      alert('Updated successfully!');
    };


const handleBackClick =() =>{
   navigate('/home');
}

const toggleCollapse = () => {
  setIsCollapsed(!isCollapsed);
};

    return (
    <div className={`questionContainer ${isCollapsed ? 'collapsed' : ''}`}>
    <div className='titleHeader' onClick={toggleCollapse}>
      <div className='arrowCon'>
        <span className={`arrowIcon ${isCollapsed ? 'up' : 'down'}`}></span>
      </div>
      <div className='titleCon'>
        <h4>Section A</h4>
      </div>
      
      </div>
      {!isCollapsed && ( 
        <>
            <table>
                <thead>
                    <tr>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="questionsColumnWidth">LPA Question</th>
                        <th className="answersColumnWidth">Responses</th>
                        <th className="scoreColumnWidth">Score</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(lpaDataQuestions.LPAQuestions) ? (
                lpaDataQuestions.LPAQuestions.map((question, index) => (
                            <tr key={index}>
                                {/* <td><div className='circle'></div></td> */}
                                <td className="questionsColumnWidth">{question.questions}</td>
                                <td className="answersColumnWidth">   
                                <TextareaAutosize
                                    minRows={3} // Set the minimum number of rows (initially 1 row)
                                    placeholder="Type your response here"
                                    style={{
                                    width: '100%', // Expand the textarea to fill the entire cell
                                    minHeight: '5px', // Make the textarea height match the row height
                                    border: 'none', // Remove borders for a cleaner appearance
                                    padding: 0, // Remove any padding inside the textarea
                                    resize: 'none', // Disable textarea resizing
                                    boxSizing: 'border-box', // Ensure the width and height include padding and border
                                    background: 'transparent', // Optional: Make the textarea background transparent
                                    overflow: 'hidden', // Hide any extra scrollbars
                                    }}
                                    value={tempResponses[index]} // Use the temporary responses for the text area
                                    onChange={(e) => {
                                        const newResponses = [...tempResponses]; // Use the temporary responses
                                        newResponses[index] = e.target.value; // Update the temporary response
                                        setTempResponses(newResponses); // Update the temporary responses state
                                    }}
                                  />
                                </td>
                                <td className="scoreColumnWidth">
                                  {!tempScores[index] ? (
                                    <select
                                      value={tempScores[index]}
                                      onChange={(e) => {
                                        const newScores = [...tempScores];
                                        newScores[index] = e.target.value;
                                        setTempScores(newScores);
                                      }}
                                    >
                                      <option value="">Score</option>
                                      <option className="passField" value="Pass">Pass</option>
                                      <option className="failField" value="Fail">Fail</option>
                                      <option value="N/A">N/A</option>
                                    </select>
                                  ) : (
                                    <span style={{ fontWeight: 'bold', color: getColorForValue(tempScores[index]), display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                      <div className='selectedOption'>
                                        {tempScores[index]}
                                      </div>
                                      <div className='clearBtnCon'>
                                        <button
                                          className='clearBtn'
                                          onClick={() => {
                                            const newScores = [...tempScores];
                                            newScores[index] = ''; // Clear the selected value
                                            setTempScores(newScores);
                                          }}
                                        >
                                          Clear
                                        </button>
                                      </div>

                                    </span>
                                  )}
                                </td>
                                <td className="notesColumnWidth">    
                                <TextareaAutosize
                                    minRows={3} // Set the minimum number of rows (initially 1 row)
                                    placeholder="Type your notes here"
                                    style={{
                                    width: '100%', // Expand the textarea to fill the entire cell
                                    minHeight: '5px', // Make the textarea height match the row height
                                    border: 'none', // Remove borders for a cleaner appearance
                                    padding: 0, // Remove any padding inside the textarea
                                    resize: 'none', // Disable textarea resizing
                                    boxSizing: 'border-box', // Ensure the width and height include padding and border
                                    background: 'transparent', // Optional: Make the textarea background transparent
                                    overflow: 'hidden', // Hide any extra scrollbars
                                    }}
                                    value={tempNotes[index]} // Use the temporary notes for the text area
                                    onChange={(e) => {
                                        const newNotes = [...tempNotes]; // Use the temporary notes
                                        newNotes[index] = e.target.value; // Update the temporary note
                                        setTempNotes(newNotes); // Update the temporary notes state
                                    }}
                                    />
                                </td>
                            </tr>
                         ))
                         ) : (
                           <tr>
                             <td colSpan="4">Loading...</td>
                           </tr>
                         )}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                <h4>Section A Score = {totalScore+"%"}</h4>
                
            </div> 
            <div className='actionBtns'>
            <div className='submitBtnCon'>
          <button
            className={`submitBtn ${!isUpdateEnabled || isFormSubmitted ? 'disabled' : ''}`}
            onClick={submitProgress}
            disabled={!isUpdateEnabled || isFormSubmitted}
          >
            Submit Progress
          </button>
        </div>
        {isFormSubmitted && (
          <div className='updateBtnCon'>
            <button className='updateBtn' onClick={updateProgress}>
              Update
            </button>
          </div>
        )}
                <div className='updateBtnCon'>
                    <button className='updateBtn' onClick={handleBackClick}>Back</button>
                </div>
            </div>
            </>
      )}
        </div>
   
    )
}

export default LpaBackups;

function getColorForValue(value) {
  switch (value) {
    case 'Pass':
      return 'green';
    case 'Fail':
      return 'red';
    case 'N/A':
      return 'blue';
    default:
      return 'black'; // Default color for other values
  }
}